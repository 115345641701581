import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  @ViewChild('recaptcha', {static: true }) recaptchaElement: ElementRef;

  homesiteToken: string;
  homesiteKey: string;
  homeremoteip: string;
  homeistrue: boolean;
  homeisformerror: boolean;
  insterror: boolean;
  homeerrordiv: boolean;
  homeapiResponse: any;
  homename: string;
  homeemail: string;
  verifyemail: boolean;
  mainform: boolean;
  dispemailerr: boolean;
  successdiv: boolean;
  homecountry: string = "Canada";
  homedomain: string = "www.autotradingsoftware.co.in";
  domain: string = "www.autotradingsoftware.co.in";
  homephone: number;
  phonecc: number;
  homecity: string;
  homebodydf: any;
  homeviewbutton: boolean;
  homeerroresponse: any;
  emailverifyerror: boolean;
  status: any;
  enqid: string;
  inemail: string;
  resparray: any;
  duplicateemail: boolean;
  phoneNo: number;
  phonerr: boolean;
  phonesucc: boolean;
  constructor(private http: HttpClient) { }

  ngOnInit() {
    this.addRecaptchaScript();
    this.getIPAddress();
    this.mainform = false;
    this.homeisformerror = true;
    this.homeviewbutton = false;
    this.homeerrordiv = true;
    this.phonerr=true;
    this.phonesucc=true;
    this.dispemailerr = true;
    this.emailverifyerror = true;
    this.successdiv = true;
    this.duplicateemail = true;
  }

  renderReCaptch() {
    window['grecaptcha'].render(this.recaptchaElement.nativeElement, {
      'sitekey' : '6LcahYEaAAAAAPjw3uS0pV0ypK7o0WSdxVQ-kTDw',
      'callback': (response) => {
          this.homesiteToken = response;
      }
    });
  }

  reLoadcaptcha() {
    window['grecaptcha'].reset(this.recaptchaElement.nativeElement);
  }

  addRecaptchaScript() {
    window['grecaptchaCallback'] = () => {
      this.renderReCaptch();
    }

    (function(d, s, id, obj){
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) { obj.renderReCaptch(); return;}
      js = d.createElement(s); js.id = id;
      js.src = "https://www.google.com/recaptcha/api.js?onload=grecaptchaCallback&amp;render=explicit";
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'recaptcha-jssdk', this));

  }

  getIPAddress()  {

    this.http.get("https://api.ipify.org/?format=json").subscribe((res:any)=>{
      this.homeremoteip = res.ip;
    });

  }

  clearErrors(event): any {

    this.homeisformerror = true;
    this.homeerrordiv = true;
  }

  numberOnly(event): boolean {
    this.homeisformerror = true;
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode == 43) {
      return true;
    } else if((charCode > 32 && (charCode < 48 || charCode > 57 ))){
      return false;
    } else {
      return true;
    }

  }

  alphabetsOnly(event): boolean {
    this.homeisformerror = true;
    const charCode = (event.which) ? event.which : event.keyCode;
    if ((charCode > 64 && charCode < 91) || (charCode > 96 && charCode < 123) || charCode == 8 || charCode == 32){
      return true;
    }
     return false;
  }


onPhone(form: NgForm){
      var phoneNo= form.value.phoneNo;
        console.warn(phoneNo)

      const headers = new HttpHeaders()
       .set('Access-Control-Allow-Origin','*');
       this.http.post('https://staffdesk.algomastersoftware.com/api/angular/sites/enquiry-ref',{phone: form.value.phoneNo, domain: form.value.domain},{'headers' : headers}).subscribe(response => {
       console.warn('got the post response',response);
       if(response == 'Success'){
         this.phonerr = false;
         this.phonesucc= true;
       }
       else{
         this.phonerr = true;
         this.phonesucc= false;
       }

         });
      }
  onSubmit(form: NgForm) {

    this.homeerrordiv = true;
    if(form.valid) {
      this.homeviewbutton = true;
      this.homeisformerror = true;
      const headers = new HttpHeaders()
     .set('Access-Control-Allow-Origin','*');
     this.http.post('https://staffdesk.algomastersoftware.com/api/client/emailtest',{name: form.value.name, phone: form.value.phone, email: form.value.email, city: form.value.city,siteToken: form.value.siteToken, remoteip: form.value.remoteip, domain: form.value.domain,country: form.value.country},{'headers' : headers}).subscribe(response => {
 console.warn(response);
 this.resparray = response;
 console.warn(response);
 if(this.resparray.status == 'Success'){
   this.mainform = true;
   this.successdiv = false;
   this.dispemailerr = true;
   this.homeisformerror = true;
 }
 else if (this.resparray.status == 'mailerror'){
   this.mainform = true;
   this.successdiv = true;
   this.emailverifyerror = false;
   this.homeviewbutton = false;
   this.verifyemail = this.resparray.inemail;
   this.enqid = this.resparray.enqid;
 }
 else if (this.resparray.status == 'validate'){
        window.location.reload();
     }
      else {
        this.homeviewbutton = false;
        this.homeisformerror = false;
      }
     },(errorResponse: HttpErrorResponse) => {
      // Extract form error messages from API  <------ HERE!!!
      this.reLoadcaptcha();
      this.homesiteToken = "";
      const errors = [];
      if (errorResponse.error) {
        this.homeviewbutton = false;
        this.homeerrordiv = false;
        //errors.push(errorResponse.error.message);
        if (errorResponse.error.errors) {
          for (const property in errorResponse.error.errors) {
            if (errorResponse.error.errors.hasOwnProperty(property)) {
              const propertyErrors: Array<string> = errorResponse.error.errors[property];
              propertyErrors.forEach(error => errors.push(error));
              this.homeerroresponse = errors;
            }
          }
        }
      }
      console.log(errors);
    });
    } else {
      this.homeisformerror = false;
    }
  }

  onEmailSubmit(form: NgForm) {
  // this.emailverifyerror = false;
  this.homeviewbutton = true;
  const headers = new HttpHeaders()
 .set('Access-Control-Allow-Origin','*');
  this.http.post('https://staffdesk.algomastersoftware.com/api/client/verify/email',{ email: form.value.verifyemail, enqid: form.value.enqid},{'headers' : headers}).subscribe(response => {

    if(response == 'true'){
      // console.log("true");
      this.emailverifyerror = true;
      this.successdiv = false;
      this.duplicateemail = true;
    }else if(response == 'duplicate'){
      this.duplicateemail = false;
      this.emailverifyerror = false;
      this.homeviewbutton = false;
    }
    else{
      console.log("false");
      this.emailverifyerror = false;
      this.homeviewbutton = false;
      this.duplicateemail = true;
    }
  },(errorResponse: HttpErrorResponse) => {
    console.log("error");
  });
}

  onSubmitw(form: NgForm){
    this.insterror = false;
    if(form.valid) {

    } else {
      this.insterror = false;
    }
  }
}
